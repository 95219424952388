<template>
  <div>

    <common-table
      ref="table"
      path="device/upDownCommand"
      search-placeholder="搜索消息内容"
      :columns="columns"
      :queryParams="queryParams"
      :date-range="true" >
    </common-table>

  </div>
</template>
<script>

  export default {
    props:["iotId"],
    data () {
      return {
        queryParams:{
          iotId:'',
        },
      }
    },
    mounted () {
      this.queryParams.iotId = this.iotId
      this.getList()
    },

    computed:{
      columns() {
        return [
          {
            title: '类型',
            dataIndex: 'type',
            customRender: (text, row, index) => {
              if (text === 0) {
                return '上行'
              } else {
                return '下行'
              }
            },
            filterMultiple: false,
            filters: [
              { text: '全部', value: '' },
              { text: '上行', value: '0' },
              { text: '下行', value: '1' }
            ],
          },
          {
            title: 'topic',
            dataIndex: 'topic',
            filterMultiple: false,
            filters: [
              { text: '全部', value: '' },
              { text: '上下线', value: '上下线' },
              { text: '属性', value: '属性' }
            ],
          },
          {
            title: '消息内容',
            dataIndex: 'content',
            ellipsis: true,
          },
          {
            title: '时间',
            dataIndex: 'createdAt',
            width: 200
          }
        ]
      },
    },
    methods:{
      getList () {
        this.$refs.table.getData()
      },
    }
  }
</script>

<style>

</style>
